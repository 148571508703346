import React from 'react'
import Layout from '@global/layout'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

const RulesPage = ({ data }) => {
	const imageData = data.desktop.childImageSharp.fluid

	return (
		<Layout>
			<BackgroundImage Tag='section' fluid={imageData} className='bg-image'>
				<section className='section wrapper wrapper--sm text-block'>
					<h1>DAO Rules</h1>
					<h2>Defiant Pixels Society</h2>
					<p>
						Defiant Pixel Society is a collective of artists, developers and
						creators that work together to generate NFTs for the benefit of
						collectively being a single brand, with fair representation based on
						value creation.
					</p>
					<h3>Overview</h3>

					<p>
						Members join together to mint and sell art under the DPS Brand.
						Members will network with other communities that require minting or
						design services.
					</p>
					<p>
						We will manage bots, NFT minting and sales contracts to promote web3
						and brand value.
					</p>

					<h3>How</h3>

					<p>
						Members are encouraged to learn, share and participate. Members may
						submit up to ​2 pieces of art per month for a share for each piece
						that gets minted by the DAO.
					</p>
					<p>
						Alternatively, individuals can participate in co-creation.
						Contributing to co-creation or group themes that get minted earn a
						share but does not count as a piece submitted for the month.
					</p>
					<p>
						This monthly “submission” gives members access to our community
						channels. All members of the DAO receive equal voting rights.
					</p>
					<p>
						Members can also earn shares by leading workshops or introducing new
						tools and techniques.
					</p>
					<h3>What</h3>

					<p>​There are three purposes of the Defiant Pixel Society DAO:</p>
					<ol>
						<li>1. Deciding which art should get minted by the DAO.</li>
						<li>
							2. Deciding which new members applications make it into the DAO.
						</li>
						<li>3. The DAO will support tools that enable web3 communities.</li>
					</ol>
					<p>
						DPS tools enable subsidized minting of artwork access to tools,
						bots, profit share of artwork, marketplaces and other members We
						will help buidl the future of web3 with an NFT first approach. We
						will utilize our skills to support our designs and other web3
						communities. Members vote on which art to mint is critical to the
						DAO’s success. It is in our mutual benefit to create great art and
						ensuring our time and work gets rewarded properly. We also all have
						a vested interest in ensuring our work and time gets rewarded
						properly.
					</p>
					<h3>Why</h3>

					<p>
						The purpose of the DAO is to curate amazing artists that want to
						contribute to a collaborative experience. We fund minting and
						explore the potential of smart contracts and automation in the field
						of NFTs.
					</p>
					<ol>
						<li>
							1. Artists will retain all IP for pieces they contribute to the
							DAO.
						</li>
						<li>
							2. Artists have the final say in allowing or disallowing
							collaboration and extension of their original work.
						</li>
						<li>
							3. Artists that create collaboratively, get rewarded for their
							contributions proportional to their effort.
						</li>
						<li>
							4. Artists receive 90% of funds from primary sales with 10% of
							sale going to the collective.
						</li>
						<li>
							5. Artists can receive royalties from secondary sales of works.
						</li>
						<li>
							6. Artists gain control of a means to fund activity and reward
							members for participation.
						</li>
						<li>7. Artists can leave the group at any time via rage quit.</li>
						<li>
							8. Artists are not required to submit art, but will be mark
							inactive if they have not submitted art within the previous 3
							months.
						</li>
						<li>
							9. Artists agree that internally shared artwork can not be
							reproduced or published in any artwork without the consent of the
							creator and will discuss or disclose per-released works they may
							gain access to.
						</li>
					</ol>
					<h3>Where</h3>
					<p>
						DPS is currently on discord and telegram and supports bots for
						discord. We have a mainnet OG DAOstack DAO formed March of 2020; but
						are formed now on DAOHaus xDai and rallying to support ChievMint!
					</p>
				</section>
			</BackgroundImage>
		</Layout>
	)
}

export default RulesPage

export const query = graphql`
	query RulesPageQuery {
		desktop: file(relativePath: { eq: "Idea.png" }) {
			childImageSharp {
				fluid(quality: 100, maxWidth: 2560) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
	}
`
